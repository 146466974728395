import React, { useState, useEffect } from 'react';

import { History } from 'history';
import {
    Switch,
    Route,
    withRouter,
    RouteComponentProps,
    matchPath,
} from 'react-router-dom';
import { Store } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { QueryString } from '@tools/queryString';
import { AdProvider } from '@rambler-components/ad';

import { desktopPadId, mobilePadId } from '@configs/ad';
import { useMobileCheck } from '@hooks/checkMobile';
import { useCheckSubscriptionPrimeWithAuth, useCheckSubscriptionPrimeWithAd } from '@hooks/checkSubscriptionPrime';
import { routesPrefetch } from '@tools/prefetchRoutes';
import { updateAdKey } from '@redux/modules/global';
import { eventsTns, eventsTop100, eventsYm } from '@tools/events';

import { changeFavicon } from '@tools/favicon';
import routes from './client/routes';

interface IPropsApp {
    store?: Store;
    history?: History;
}

const App: React.FC<IPropsApp & RouteComponentProps> = ({ store, history }) => {
    const { location: { pathname, search } } = history;
    const [isMounted, setMounted] = useState(false);
    const query = QueryString.getObject(search);
    const isMobile = useSelector(({ runtime }) => runtime.isMobile);
    const dispatch = useDispatch();
    const currentRoute = routes.find(route => !!matchPath(pathname, route));

    useMobileCheck(isMobile);
    useCheckSubscriptionPrimeWithAuth();
    useCheckSubscriptionPrimeWithAd();

    useEffect(() => {
        setMounted(true);

        if (isMounted) {
            dispatch(updateAdKey());

            routesPrefetch({
                pathname,
                store,
                query,
            });
            eventsTns.setPageView();
            eventsTop100.setPageView();
            eventsYm.setPageView(`${pathname}${search}`);

            window.scrollTo(0, 0);

            changeFavicon(currentRoute.favicons);
        }
    }, [currentRoute.path]);


    return (
        <AdProvider padId={isMobile ? mobilePadId : desktopPadId}>
            <Switch>
                {(routes).map(route => <Route {...route} key={route.id} />)}
            </Switch>
        </AdProvider>
    );
};

export default withRouter(App);
