import { Store } from 'redux';
import { IAction } from 'typings/redux';
import { TableQuery } from '@tools/conversionQuery';
import {
    reduxFetch,
    createReducer,
    generateActions,
    createConstants,
} from '@redux/helpers';
import api from '@constants/api';

interface IState {
    request: boolean;
    error: boolean;
    errorMessage: {};
    resourceId: number;
    body: {};
    shouldRedirect: boolean;
}

export const initialState: IState = {
    request: false,
    error: false,
    errorMessage: {},
    resourceId: 0,
    body: {},
    shouldRedirect: false,
};

export const POSITION_CONSTANTS = createConstants('GET_POSITION');

export const reducer = {
    [POSITION_CONSTANTS.REQUEST]: {
        reducer: (state: IState) => ({
            ...state,
            request: true,
        }),
    },
    [POSITION_CONSTANTS.FAILURE]: {
        reducer: (state: IState, action: IAction) => ({
            ...state,
            request: false,
            error: true,
            ...action.payload,
        }),
    },
    [POSITION_CONSTANTS.RECEIVE]: {
        reducer: (state: IState, action: IAction) => {
            const { resourceId } = action.meta;
            const { data } = action.payload;

            const body = Object.keys(data).reduce((acc, curr) => (
                data[curr]
                    ? { ...acc, ...TableQuery.sync(data[curr]) }
                    : acc
            ), {});

            return {
                ...state,
                request: false,
                shouldRedirect: true,
                resourceId,
                body,
            };
        },
    },
    UPDATE_REDIRECT_KEY: {
        action: (key: boolean) => ({
            type: 'UPDATE_REDIRECT_KEY',
            payload: { key },
        }),
        reducer: (state: IState, action: IAction) => {
            const { key } = action.payload;
            return {
                ...state,
                shouldRedirect: key,
            };
        },
    },
    CLEAR_FILTER: {
        reducer: () => ({
            ...initialState,
        }),
    },
};

export const fetchPosition = (id: number = 0): any => (
    dispatch: Store['dispatch'],
) => reduxFetch({
    dispatch,
    constant: POSITION_CONSTANTS,
    url: `${api.catalogue}/position/${id}`,
    meta: { resourceId: id },
    getData: data => ({ data }),
});

export const actions: Record<string, any> = generateActions(reducer);
export default createReducer(reducer, initialState);
