import { Store } from 'redux';
import api from '@constants/api';
import { createConstants, reduxFetch } from '@redux/helpers';

const regionConstants = createConstants('GET_REGION');

export interface IState {
    loaded: boolean;
    request: boolean;
    error: boolean;
    errorMessage: {};
    topLevelRegion: number[];
    data: { [id: number]: IRegionType };
}

interface IAction {
    type: string;
    payload: {
        data: IRegionType[];
        error: boolean;
        errorMessage: Record<any, string>;
        topLevelRegion: number[];
    };
}

type Reducer = (
    state: IState,
    action: IAction
) => IState;

export const initialState: IState = {
    loaded: false,
    request: false,
    error: false,
    errorMessage: {},
    data: {},
    topLevelRegion: [],
};

export const setRequest = (state: IState) => ({
    ...state,
    request: true,
    loaded: false,
});

export const setData = (state: IState, action: IAction) => {
    const { data } = action.payload;

    const mappedData = (arr, obj) => arr.reduce((acc, current) => {
        const newAcc = current.children.length ? mappedData(current.children, acc) : acc;

        return { ...newAcc, [current.id]: current };
    }, obj);

    const topLevelRegion = data.map(item => item.id);

    return {
        ...state,
        request: false,
        loaded: true,
        data: mappedData(data, {}),
        topLevelRegion,
    };
};

export const setError = (state: IState, action: IAction) => ({
    ...state,
    request: false,
    error: true,
    loaded: false,
    ...action.payload,
});

const reducer: Reducer = (state = initialState, action) => {
    switch (action.type) {
        case regionConstants.REQUEST: return setRequest(state);
        case regionConstants.RECEIVE: return setData(state, action);
        case regionConstants.FAILURE: return setError(state, action);

        default:
            return state;
    }
};

/**
 * Запрос за списком регионов
 */
export const fetchRegion = (): any => (
    dispatch: Store['dispatch'],
) => reduxFetch({
    dispatch,
    constant: regionConstants,
    url: `${api.reportsV1}/region`,
});

export default reducer;
