import { Store } from 'redux';
import api from '@constants/api';

import { createConstants, reduxFetch } from '@redux/helpers';

const sideBlockConstants = createConstants('GET_SIDE_BLOCK');

interface IState {
    loaded: boolean;
    request: boolean;
    error: boolean;
    errorMessage: {};
    popularSites: ISites[];
    newSites: ISites[];
}

interface IAction {
    type: string;
    payload: {
        data: {
            popular_sites: ISites[];
            new_sites: ISites[];
        };
        error: boolean;
        errorMessage: {};
    };
}

type Reducer = (
    state: IState,
    action: IAction
) => IState;

export const initialState: IState = {
    loaded: false,
    request: false,
    error: false,
    errorMessage: {},
    newSites: [],
    popularSites: [],
};

export const setRequest = (state: IState) => ({
    ...state,
    request: true,
    loaded: false,
});

export const setData = (state: IState, action: IAction) => {
    const { new_sites: newSites, popular_sites: popularSites } = action.payload.data;

    return {
        ...state,
        request: false,
        loaded: true,
        newSites,
        popularSites,
    };
};

export const setError = (state: IState, action: IAction) => ({
    ...state,
    request: false,
    error: true,
    loaded: false,
    ...action.payload,
});

const reducer: Reducer = (state = initialState, action) => {
    switch (action.type) {
        case sideBlockConstants.REQUEST: return setRequest(state);
        case sideBlockConstants.RECEIVE: return setData(state, action);
        case sideBlockConstants.FAILURE: return setError(state, action);

        default:
            return state;
    }
};

export const fetchSideBlock = (regionId: number): any => (
    dispatch: Store['dispatch'],
) => reduxFetch({
    dispatch,
    constant: sideBlockConstants,
    url: `${api.catalogue}/side_block`,
    body: { region_id: regionId }, // eslint-disable-line @typescript-eslint/camelcase
    getData: data => ({ data }),
});

export default reducer;
