import { Store } from 'redux';
import { IAction } from 'typings/redux';
import {
    reduxFetch,
    createReducer,
    generateActions,
    createConstants,
} from '@redux/helpers';
import { getParamsForRequest } from '@tools/getParamsForRequest';
import api from '@constants/api';
import { filtersDefault } from '@configs/filters';

interface IState {
    request: boolean;
    error: boolean;
    errorMessage: Record<any, string>;
    data: SitesType[];
    projectCount: number;
    currentPage: number;
}

interface IExtendsAction extends IAction {
    meta?: {
        currentPage;
        resourceId;
    };
}

export const initialState: IState = {
    request: false,
    error: false,
    errorMessage: {},
    data: [],
    projectCount: 0,
    currentPage: 1,
};

export const GET_SITES_CONSTANT = createConstants('GET_SITES');

export const reducer = {
    [GET_SITES_CONSTANT.REQUEST]: {
        reducer: (state: IState) => ({
            ...state,
            request: true,
        }),
    },
    [GET_SITES_CONSTANT.FAILURE]: {
        reducer: (state: IState, action: IAction) => ({
            ...initialState,
            request: false,
            error: true,
            ...action.payload,
        }),
    },
    [GET_SITES_CONSTANT.RECEIVE]: {
        reducer: (state: IState, action: IExtendsAction) => {
            const { data } = action.payload;
            const { currentPage } = action.meta;
            const { project_count: projectCount } = data;

            return {
                ...state,
                request: false,
                data: data.result,
                projectCount,
                currentPage,
                error: !data.result.length,
            };
        },
    },
    SET_CURRENT_PAGE: {
        action: (currentPage: number) => ({
            type: 'SET_CURRENT_PAGE',
            payload: { currentPage },
        }),
        reducer: (state: IState, action: IAction) => {
            const { currentPage } = action.payload;
            return {
                ...state,
                currentPage,
            };
        },
    },
    SET_SORT: {
        reducer: (state: IState) => ({
            ...state,
            request: true,
            currentPage: 1,
        }),
    },
    CLEAR_SITES: {
        action: () => ({
            type: 'CLEAR_SITES',
        }),
        reducer: (state: IState) => ({
            ...state,
            request: false,
            error: false,
            errorMessage: {},
            data: [],
            projectCount: 0,
            currentPage: 1,
        }),
    },
    SET_FILTER: {
        reducer: (state: IState) => ({
            ...state,
            currentPage: 1,
        }),
    },
    SET_FILTERS: {
        reducer: (state: IState) => ({
            ...state,
            currentPage: 1,
        }),
    },
    CLEAR_FILTER: {
        reducer: (state: IState) => ({
            ...state,
            currentPage: 1,
        }),
    },
};

export const fetchSites = (filters: any = {}) => (
    dispatch: Store['dispatch'],
) => {
    const currentPage = !filters.page ? 1 : Number(filters.page);
    const newFilters = filters;
    newFilters.period = filters.period || filtersDefault.period;
    newFilters.sort = filters.sort || filtersDefault.sort;

    const body: Record<string, string | number> = getParamsForRequest(newFilters, true);

    return reduxFetch({
        dispatch,
        constant: GET_SITES_CONSTANT,
        url: `${api.catalogue}/table`,
        body,
        meta: { currentPage },
        getData: data => ({ data }),
    });
};

export const actions: Record<any, any> = generateActions(reducer);
export default createReducer(reducer, initialState);
