import { AppRoute } from 'typings/AppRoute';

import Home from '@pages/Home';
import Navi from '@pages/Navi';
import Search from '@pages/Search';
import NotFound from '@pages/NotFound';
import Stat from '@pages/Stat';

const routes: AppRoute[] = [
    Home,
    Navi,
    Search,
    Stat,
    NotFound,
];

export default routes;
