import { defaultTexts } from '@constants/defaultTexts';

export const stubs = {
    types: {
        id: 0,
        text: defaultTexts.types,
    },
    subcategories: {
        id: 0,
        text: defaultTexts.subtopics,
    },
    topics: {
        id: 0,
        text: defaultTexts.topics,
    },
    regions: {
        title: defaultTexts.regions,
        id: 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        parent_id: null,
        children: [],
    },
};
