export interface IBanner {
    id: number;
    bannerName: string;
    bannerNumber: number;
}

export interface IAds {
    all?: Record<string, IBanner>;
    mobile?: Record<string, IBanner>;
    desktop?: Record<string, IBanner>;
}

export const desktopPadId = 432340058;

export const mobilePadId = 469202650;

export const banners: { [name: string]: IBanner } = {
    billboard: {
        id: 432340834,
        bannerName: 'billboard',
        bannerNumber: 0,
    },
    right1: {
        id: 432340830,
        bannerName: '240x400Desktop',
        bannerNumber: 0,
    },
    right2: {
        id: 432340806,
        bannerName: '240x200Desktop',
        bannerNumber: 0,
    },
    footer: {
        id: 436061116,
        bannerName: 'footer',
        bannerNumber: 0,
    },
    superFooter: {
        id: 432340580,
        bannerName: 'superFooter',
        bannerNumber: 0,
    },
    native1: {
        id: 436061114,
        bannerName: 'native',
        bannerNumber: 0,
    },
    native2: {
        id: 522994477,
        bannerName: 'native',
        bannerNumber: 0,
    },
    listing1: {
        id: 469238152,
        bannerName: 'listing',
        bannerNumber: 0,
    },
    mobileTop: {
        id: 469202694,
        bannerName: 'mobileTop',
        bannerNumber: 0,
    },
    mobileFooter: {
        id: 469251150,
        bannerName: 'mobileFooter',
        bannerNumber: 0,
    },
};

export const layoutAds: IAds = {
    mobile: {
        top: banners.mobileTop,
        bottom: banners.mobileFooter,
    },
    desktop: {
        right1: banners.right1,
        right2: banners.right2,
        top: banners.billboard,
        bottom: banners.footer,
    },
};

export const homeAds: IAds = {
    mobile: {
        0: banners.listing1,
    },
    desktop: {
        6: banners.native1,
    },
};

export const sitesTableAds: IAds = {
    mobile: {
        10: banners.listing1,
    },
    desktop: {
        10: { ...banners.native1, bannerNumber: 1 },
        15: banners.superFooter,
        25: { ...banners.native2, bannerNumber: 2 },
    },
};
