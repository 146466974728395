interface IOptions {
    parseNumbers?: boolean;
}

const customSplit = (str: string, separator: string) => {
    if (separator === '') {
        return [str];
    }

    const separatorIndex = str.indexOf(separator);

    if (separatorIndex === -1) {
        return [str];
    }

    return [
        str.slice(0, separatorIndex),
        str.slice(separatorIndex + separator.length),
    ];
};

// поля которые не нужно парсить в число
// например query может состоять из одних чисел, его нужно проигнорировать
const exceptionParse = ['query'];

const parseValue = (
    key: string,
    value: string,
    options: { parseNumbers?: boolean },
) => {
    if (
        options.parseNumbers
        && !Number.isNaN(Number(value))
        && typeof value === 'string'
        && value.trim() !== ''
        && !exceptionParse.includes(key)
    ) {
        return Number(value);
    }

    return value;
};

export class QueryString {
    static getObject(str: string, options: IOptions = {}): Record<string, string> {
        const result = {};
        if (typeof str !== 'string') {
            return result;
        }

        const pureStr = str.trim().replace(/^[?#&]/, '');

        if (!pureStr) {
            return result;
        }

        return pureStr.split('&').reduce((acc, curr) => {
            const [key, value] = customSplit(curr, '=');

            const decodedValue = value !== undefined
                ? decodeURI(value)
                : value;

            if (acc[key] === undefined) {
                return { ...acc, [key]: parseValue(key, decodedValue, options) };
            }

            return { ...acc, [key]: [].concat(acc[key], decodedValue) };
        }, result);
    }

    static getString(params: Record<string, any>) {
        if (!params) {
            return '';
        }

        const keys = Object.keys(params);

        return keys.map((key) => {
            const value = params[key];

            if (value === undefined) {
                return '';
            }

            if (value === null) {
                return encodeURIComponent(key);
            }

            if (Array.isArray(value)) {
                return value.reduce((acc, curr) => acc.concat(`${key}=${curr}`), []).join('&');
            }

            return `${key}=${encodeURIComponent(value)}`;
        }).filter(x => x.length > 0).join('&');
    }
}
