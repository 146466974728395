import { IAction } from 'typings/redux';
import { Store } from 'redux';
import api from '@constants/api';
import {
    createConstants,
    createReducer,
    generateActions,
    reduxFetch,
} from '@redux/helpers';
import { filtersDefault } from '@configs/filters';
import { getRegionIds } from '@tools/getRegionIds';
import { getParamsForRequest } from '@tools/getParamsForRequest';

export const GET_FILTERS_CONSTANT = createConstants('GET_FILTERS');

interface IInitialFiltersData {
    regionData: { [id: number]: IRegionType };
}

export interface IState {
    error: boolean;
    request: boolean;
    regionIds: number[];
    activeFilters: IActiveFilters;
    types: { [id: number]: number };
    regions: { [id: number]: number };
    categories: { [id: number]: number };
}

export const initialState: IState = {
    request: false,
    error: false,
    categories: {},
    regions: {},
    types: {},
    regionIds: [],
    activeFilters: {
        regionId: 0,
        categoryId: 0,
        subcategoryId: 0,
        period: filtersDefault.period,
        typeId: 0,
        query: '',
        sort: filtersDefault.sort,
        isCatOpen: 0,
        page: 0,
    },
};

const prepareInitialFilters = (state: IState, query: Record<string, any>) => {
    let result: IState = { ...state };

    const filterKeys = ['categoryId', 'subcategoryId', 'regionId', 'typeId'];
    const keys = Object.keys(query);

    if (!keys.length) return result;

    keys.forEach((key) => {
        if (filterKeys.includes(key)) {
            result = {
                ...result,
                activeFilters: {
                    ...result.activeFilters,
                    [key]: Number(query[key]),
                },
            };
        }
    });

    return result;
};

export const reducer = {
    [GET_FILTERS_CONSTANT.REQUEST]: {
        reducer: (state: IState) => ({
            ...state,
            request: true,
        }),
    },
    [GET_FILTERS_CONSTANT.FAILURE]: {
        reducer: (state: IState, action: IAction) => ({
            ...state,
            request: false,
            error: true,
            ...action.payload,
        }),
    },
    [GET_FILTERS_CONSTANT.RECEIVE]: {
        reducer: (state: IState, action: IAction) => {
            const { data } = action.payload;

            const prepare = (originData: { [id: number]: number}) => ({
                0: null,
                ...originData,
            });

            return {
                ...state,
                request: false,
                error: false,
                categories: prepare(data.categories),
                regions: prepare(data.regions),
                types: prepare(data.types),
            };
        },
    },
    SET_SORT: {
        action: ({ key, order }: { key: string; order: string }) => ({
            type: 'SET_SORT',
            payload: { key, order },
        }),
        reducer: (state: IState, action: IAction) => {
            const { payload } = action;

            return {
                ...state,
                activeFilters: {
                    ...state.activeFilters,
                    sortBy: payload,
                },
            };
        },
    },
    SET_INITIAL_FILTERS: {
        action: (query: Record<string, any>) => ({
            type: 'SET_INITIAL_FILTERS',
            payload: { query },
        }),
        reducer: (state: IState, action: IAction) => {
            const { payload: { query } } = action;
            return { ...prepareInitialFilters(state, query) };
        },
    },
    SET_FILTERS: {
        action: (filters: Record<string, any>) => ({
            type: 'SET_FILTERS',
            payload: { filters },
        }),
        reducer: (state: IState, action: IAction) => {
            const { payload: { filters } } = action;

            return {
                ...state,
                activeFilters: {
                    ...initialState.activeFilters,
                    ...filters,
                },
            };
        },
    },
    SET_REGION_IDS: {
        action: (regionId, data) => ({
            type: 'SET_REGION_IDS',
            payload: { regionId, data },
        }),
        reducer: (state: IState, action: IAction) => {
            const { regionId, data } = action.payload;
            const regionIds = getRegionIds(regionId, data);

            return {
                ...state,
                regionIds,
            };
        },
    },
    CLEAR_FILTER: {
        action: (isSearchPage: boolean) => ({
            type: 'CLEAR_FILTER',
            meta: {
                isSearchPage,
            },
        }),
        reducer: (state: IState) => ({
            ...state,
            activeFilters: initialState.activeFilters,
        }),
    },
};

export const fetchFilters = (filters: any = {}): any => (
    dispatch: Store['dispatch'],
) => {
    const body: Record<string, string | number> = getParamsForRequest(filters);

    return reduxFetch({
        dispatch,
        constant: GET_FILTERS_CONSTANT,
        url: `${api.catalogue}/filters`,
        body,
        getData: data => ({ data }),
    });
};

export const actions: Record<any, any> = generateActions(reducer);
export default createReducer(reducer, initialState);
