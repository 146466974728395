import { stubs } from '@constants/stubs';

export const normalizeTypes = (data: ITypes[]) => {
    const result = (data ? data.map(item => ({
        id: item.id,
        text: item.title || item.text,
    })) : []);

    return [
        stubs.types,
        ...result,
    ];
};

export const normalizeSubcategories = (
    data: { [id: number]: ICategoryType },
    categoryId: number,
) => {
    if (!categoryId) return [];

    const item = data[categoryId];

    const result = (item && item.children ? item.children.map(i => ({
        id: i.theme_id,
        text: i.title,
    })) : []);

    return [
        stubs.subcategories,
        ...result,
    ];
};

export const normalizeData = (
    allTopicIds: number[],
    data: { [id: number]: ICategoryType },
) => {
    const items = allTopicIds.map((id) => {
        const item = data[id];

        return {
            id: item.id,
            text: item.title,
        };
    });

    return [
        stubs.topics,
        ...items,
    ];
};
