import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setRuntimeVariable } from '@redux/modules/runtime';
import { updateAdKey } from '@redux/modules/global';

/**
 * Функция, которая проверяет подписку
 */
const checkPrimeSubscription = (dispatch: Function, isUpdateAdKey: boolean = false) => {
    const { primeHelper } = require('@rambler-prime/helper');
    primeHelper.getSubscription({
        referrer: window.location.origin,
    }, (error, value: boolean) => {
        dispatch(setRuntimeVariable({ name: 'hasPrimeSubscription', value }));
        dispatch(setRuntimeVariable({ name: 'initializedPrimeSubscription', value: true }));
        if (isUpdateAdKey) dispatch(updateAdKey());

        // eslint-disable-next-line @typescript-eslint/camelcase
        if (window.top100Prime) window.top100Prime.sendCustomVars({ prime_enabled: value });
    });
};

/**
 * Хук, который следит за авторизацией, и проверяет подписку
 */
export const useCheckSubscriptionPrimeWithAuth = (): void => {
    const dispatch = useDispatch();
    const cb = () => checkPrimeSubscription(dispatch, true);

    useEffect(() => {
        window.ramblerIdHelper = window.ramblerIdHelper || [];
        window.ramblerIdHelper.push(() => {
            window.ramblerIdHelper.addListener('login', cb);
            window.ramblerIdHelper.addListener('oauthlogin', cb);
            window.ramblerIdHelper.addListener('logout', cb);
        });
    });
};

/**
 * Хук, который следит за подпиской
 */
export const useCheckSubscriptionPrimeWithAd = (): void => {
    const dispatch = useDispatch();

    useEffect(() => checkPrimeSubscription(dispatch), [dispatch]);
};
