import { DEFAULT_PAGE_LIMIT } from '@constants/global';

interface IFilters {
    page?: number;
    sort?: string;
    query?: string;
    typeId?: number;
    period?: string;
    regionId?: number;
    isCatOpen?: number;
    categoryId?: number;
    subcategoryId?: number;
}

const properties = {
    regionId: (filters: IFilters, key: string) => (
        filters[key] ? { region_id: filters[key] } : null), // eslint-disable-line

    categoryId: (filters: IFilters, key: string) => (
        filters[key] ? { category_id: filters[key] } : null), // eslint-disable-line

    subcategoryId: (
        filters: IFilters,
        key: string,
        isBackendFormat: boolean,
    ) => {
        const newKey = isBackendFormat ? 'category_id' : 'subcategory_id';
        return filters[key] ? { [newKey]: filters[key] } : null;
    },

    typeId: (filters: IFilters, key: string) => (
        filters[key] ? { type_id: filters[key] } : null), // eslint-disable-line

    page: (filters: IFilters, key: string, isBackendFormat: boolean) => (
        isBackendFormat
            ? { offset: filters[key] * DEFAULT_PAGE_LIMIT - DEFAULT_PAGE_LIMIT }
            : null
    ),

    default: (filters: IFilters, key: string) => ({ [key]: filters[key] }),
};

export const getParamsForRequest = (
    filters: IFilters = {},
    isBackendFormat: boolean = false,
) => Object.keys(filters)
    .reduce((acc, currentValue) => {
        const value = properties[currentValue]
            ? properties[currentValue](filters, currentValue, isBackendFormat)
            : properties.default(filters, currentValue);
        return { ...acc, ...value };
    }, {});
