const UPDATE_AD_KEY = 'UPDATE_AD_KEY';
const SET_SNACKBAR = 'SET_SNACKBAR';
const CLEAR_SNACKBAR = 'CLEAR_SNACKBAR';

export interface ISnackbar {
    message: string;
    showClose?: boolean;
    delay?: number;
    positionY?: 'top' | 'bottom';
    positionX?: 'left' | 'center' | 'right' | 'center';
    size?: 'small' | 'medium';
    type?: 'main' | 'primary' | 'success' | 'danger';
    className?: string;
    onClose?: Function;
}

interface IState {
    adKey: number;
    snackbar: null | ISnackbar;
}

const initialState: IState = {
    adKey: 1,
    snackbar: null,
};

export default function reducer(state: IState = initialState, action) {
    switch (action.type) {
        case UPDATE_AD_KEY:
            return {
                ...state,
                adKey: state.adKey + 1,
            };

        case SET_SNACKBAR:
            return {
                ...state,
                snackbar: action.snackbarParams,
            };

        case CLEAR_SNACKBAR:
            return {
                ...state,
                snackbar: initialState.snackbar,
            };

        default:
            return state;
    }
}

export const updateAdKey = () => ({ type: UPDATE_AD_KEY });
export const setSnackbarAction = (snackbarParams: ISnackbar) => ({
    type: SET_SNACKBAR,
    snackbarParams,
});
export const clearSnackbarAction = () => ({ type: CLEAR_SNACKBAR });
