import loadable from '@loadable/component';

import { AppRoute } from 'typings/AppRoute';

const NotFound = loadable(() => import('./NotFound'));

const route: AppRoute = {
    path: '*',
    id: 4,
    component: NotFound,
};

export default route;
