import { matchPath } from 'react-router-dom';
import { IRouteContext } from 'typings/AppRoute';

import { baseIcons, IIcons } from '@configs/favicons';

import routes from '../client/routes';

interface IRoutesChangeFavicon {
    pathname: string;
}

export const getFavicon = (pathname: string) => {
    const curRoute = routes.find((route) => {
        const routeContext: IRouteContext = matchPath(pathname, route);
        const match = routeContext !== null;

        return match && route.favicons;
    });

    if (!curRoute) return baseIcons;

    return curRoute.favicons;
};

export const changeFavicon = (icons: IIcons[]) => {
    const nodes = document.querySelectorAll(
        'link[rel="icon"], link[rel = "shortcut icon"], link[rel="apple-touch-icon"], link[rel="mask-icon"]',
    );

    for (let i = 0; i < nodes.length; i++) {
        nodes[i].parentNode.removeChild(nodes[i]);
    }

    const head = document.querySelector('head');

    icons.forEach((favIcon) => {
        const setFavicon = document.createElement('link');
        setFavicon.setAttribute('rel', favIcon.rel);
        setFavicon.setAttribute('sizes', favIcon.sizes);
        setFavicon.setAttribute('href', favIcon.href);

        head.appendChild(setFavicon);
    });
};
