import {
    createStore, applyMiddleware, StoreEnhancer, Reducer,
} from 'redux';
import thunk from 'redux-thunk';
import { name, version } from '../../package.json';

// TODO подключить логгер
export default function configureStore(
    reducers: Reducer,
    initialState: any,
) {
    const middleware = [thunk];
    let enhancer: StoreEnhancer;

    if ((__SERVER__ || (__BROWSER__ && __DEV__)) && !__TEST__) {
        const createLogger = require('./middlewares/logger').default;
        middleware.push(createLogger());
    }

    if (__DEV__) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
        const { composeWithDevTools } = require('redux-devtools-extension/developmentOnly');
        const composeEnhancers = composeWithDevTools({
            name: `${name}@${version}`,
        });
        enhancer = composeEnhancers(
            applyMiddleware(...middleware),
        );
    } else {
        enhancer = applyMiddleware(...middleware);
    }

    const store = createStore(reducers, initialState, enhancer);

    if (__DEV__ && module.hot) {
        module.hot.accept('../redux/reducers');
    }

    return store;
}
