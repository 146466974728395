import { combineReducers } from 'redux';
import runtime from './modules/runtime';
import category from './modules/configs/category';
import region from './modules/configs/region';
import types from './modules/configs/types';
import sites from './modules/sites';
import filters from './modules/filters';
import totals from './modules/totals';
import sideBlock from './modules/sideBlock';
import global from './modules/global';
import position from './modules/position';
import user from './modules/user';
import form from './modules/form';

export default combineReducers({
    runtime,
    category,
    region,
    types,
    sites,
    filters,
    totals,
    sideBlock,
    global,
    user,
    form,
    position,
});
