/* eslint-disable no-underscore-dangle */
import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApplyTheme } from 'rambler-ui/theme';
import { loadableReady } from '@loadable/component';
import reduxApp from '@redux/reducers';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import configureStore from './redux/configure';

declare const window: EnhancedWindow;

const app = () => {
    const root = document.getElementById('root');
    const store = configureStore(reduxApp, window.__PRELOADED_STATE__);
    delete window.__PRELOADED_STATE__;

    const requireAll = (r) => { r.keys().forEach(r); };
    requireAll(require.context('_sources/sprite', true, /\.svg/));

    loadableReady(() => {
        const renderMethod = module.hot ? render : hydrate;
        renderMethod(
            <ApplyTheme>
                <BrowserRouter>
                    <Provider store={store}>
                        <App store={store} />
                    </Provider>
                </BrowserRouter>
            </ApplyTheme>,
            root,
        );
    });
};

if (module.hot) {
    module.hot.accept();
}

if (!__DEV__) {
    const sentryDsn = __SENTRY_DSN__ || 'https://72ab52ef6152468ea9162145202572a9@sentry.sca.ad-tech.ru/16';

    Sentry.init({
        dsn: sentryDsn,
        release: __RELEASE__,
        environment: 'production',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

try {
    app();
} catch (err) {
    Sentry.captureException(err);
}
