export const getCookieObject = (cookieStr: string = ''): Record<string, string> => {
    const arr = cookieStr.split('; ');
    const res = {};

    arr.forEach((item) => {
        const [key, value] = item.split('=');

        res[key] = value;
    });

    return res;
};
