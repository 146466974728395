interface IApi {
    catalogue?: string;
    reportsV1?: string;
}

let api: IApi = { // eslint-disable-line import/no-mutable-exports
    catalogue: '/api/catalogue/v2.0',
    reportsV1: '/api/v1.0',
};

if (__SERVER__) {
    const appConfig = require('@server/config').default;

    api = {
        catalogue: `${appConfig.api.catalogue}/api/catalogue/v2.0`,
        reportsV1: `${appConfig.api.reportsV1}/api/v1.0`,
    };
}

export const authHeaders = { 'X-Cerber-Auth-Type': 'rsid' };

export const API = Symbol('api-middleware');

export default api;
