import isEqual from 'lodash/isEqual';
import { DEFAULT_PAGE_LIMIT } from '@constants/global';

import { SORT_VIEWERS, SORT_POPULARITY, SORT_VIEWS } from '@constants/sort';

export class TableQuery {
    static config = {
        theme: (value) => {
            const splitValue = value.split('/');

            if (!splitValue.length) {
                return {};
            }

            return {
                categoryId: splitValue[0],
                ...(splitValue[1] ? { subcategoryId: splitValue[1] } : {}),
            };
        },
        type: typeId => ({ typeId }),
        rgn: regionId => ({ regionId }),
        cat_open: isCatOpen => ({ isCatOpen }), // eslint-disable-line
        statord: (value) => {
            const values = [SORT_POPULARITY, SORT_VIEWERS, SORT_VIEWS];
            const newValue = values[Number(value)];
            return {
                sort: newValue || values[0],
            };
        },
        category_id: categoryId => ({ categoryId }), // eslint-disable-line
        type_id: typeId => ({ typeId }), // eslint-disable-line
        subcategory_id: subcategoryId => ({ subcategoryId }), // eslint-disable-line
        position: (position) => {
            const page = Math.floor(((position - 1) + DEFAULT_PAGE_LIMIT) / DEFAULT_PAGE_LIMIT);
            return { page };
        },
    };

    static sync(query = {}) {
        const { config } = this;

        let resSync = {};
        const keys = Object.keys(query);

        keys.forEach((keyQuery) => {
            const valueQuery = query[keyQuery];
            const itemConfig = config[keyQuery];

            if (itemConfig) {
                const updatedParams = itemConfig(valueQuery);
                resSync = { ...resSync, ...updatedParams };
            } else {
                resSync[keyQuery] = valueQuery;
            }
        });

        return resSync;
    }

    static isDiff(query = {}) {
        const syncQuery = TableQuery.sync(query);

        return !isEqual(query, syncQuery);
    }
}
