import loadable from '@loadable/component';
import { AppRoute } from 'typings/AppRoute';
import { openGraph } from '@configs/openGraph';
import { baseIcons } from '@configs/favicons';

const DeprecatedStat = loadable(() => import('./DeprecatedStat'));

const route: AppRoute = {
    exact: true,
    path: '/stat',
    id: 5,
    component: DeprecatedStat,
    openGraph,
    favicons: baseIcons,
};

export default route;
