import fetch from 'isomorphic-fetch';
import { oneLineTrim } from 'common-tags';
import { Store } from 'redux';

import { authHeaders } from '@constants/api';

export interface IConstants {
    REQUEST: string;
    RECEIVE: string;
    FAILURE: string;
}

/**
 * Генерация констант для экшенов
 */
export const createConstants = (type: string): IConstants => ({
    REQUEST: `${type}_REQUEST`,
    RECEIVE: `${type}_RECEIVE`,
    FAILURE: `${type}_FAILURE`,
});

/**
 * Хелпер для получения путя с get параметрами
 */
export const pathWithBody = (path: string, body: Record<string, any> = {}) => {
    const keys = Object.keys(body);

    if (!keys.length) return path;

    return oneLineTrim(`
        ${path}?
        ${keys.map(key => `${key}=${encodeURIComponent(body[key])}`).join('&')}
    `);
};

/**
 * Генерируем объект с экшенами
 */
export const generateActions = (config): Record<string, any> => {
    const keys = Object.keys(config);
    const result = {};
    keys.forEach((key) => {
        if (config[key] && config[key].action) {
            result[key] = config[key].action;
        }
    });
    return result;
};

/**
 * Создаем редьюсер
 */
export const createReducer = (
    config,
    initialState = {},
) => (state = { ...initialState }, action) => {
    const item = config[action.type];
    if (!item) {
        return state;
    }
    return item.reducer(state, action);
};

interface IReduxFetch {
    dispatch: Store['dispatch'];
    url: string;
    constant: IConstants;
    method?: 'get'|'post';
    body?: Record<string, any>;
    headers?: Record<string, any>;
    meta?: {};
    getData?: Function;
    timeout?: number|null;
}

/**
 * Унифицированная функция для запросов
 */
export const reduxFetch = async ({
    dispatch,
    method = 'get',
    constant,
    url,
    body = {},
    meta = {},
    headers = {},
    getData,
    timeout = null,
}: IReduxFetch) => {
    try {
        const controller = __BROWSER__ && window.AbortController
            ? new AbortController()
            : null;
        let signal = null;
        let isTimeouted = false;
        if (timeout && controller) {
            // eslint-disable-next-line prefer-destructuring
            signal = controller.signal;
            setTimeout(() => {
                controller.abort();
            }, timeout);
        } else if (timeout && !controller) {
            setTimeout(() => {
                isTimeouted = true;
            }, timeout);
        }

        const { REQUEST, FAILURE, RECEIVE } = constant;

        const resultUrl = method === 'get' ? pathWithBody(url, body) : url;

        const req = { url, method };

        const dispatchFailure = err => dispatch({
            type: FAILURE,
            payload: { ...err },
            meta,
            req,
        });

        await dispatch({ type: REQUEST, req, meta });

        return fetch(resultUrl, {
            method,
            body: method !== 'get' ? JSON.stringify(body) : undefined,
            headers: {
                ...headers,
                ...authHeaders,
            },
            credentials: 'include',
            signal,
        }).then(async (res) => {
            if (isTimeouted && !controller) {
                return dispatchFailure({
                    errorCode: 20,
                    errorMessage: 'Timeout',
                });
            }

            if (res.status === 403) {
                return dispatchFailure({
                    errorCode: 403,
                    errorMessage: 'Forbidden',
                });
            }

            if (res.status !== 200) {
                throw new Error('Bad response');
            }

            const rawData = await res.json();
            const payload = typeof getData === 'function' ? getData(rawData) : rawData;

            return dispatch({
                type: RECEIVE,
                payload,
                meta,
                req,
            });
        }).catch(err => dispatchFailure(err));
    } catch (e) {
        return console.log('------ ERROR: ', e); // eslint-disable-line no-console
    }
};

export default reduxFetch;
