const getCounter = (counterName: string, methodName: string, args: any[]) => {
    const counter = window[counterName];

    if (!counter) {
        return;
    }

    if (typeof counter[methodName] === 'function') {
        counter[methodName](...args);
    }
};

export const eventsTns = {
    setPageView: () => getCounter('tnsCounterRambler_ru', 'hit', ['rambler_top100']),
};

export const eventsTop100 = {
    setPageView: () => {
        getCounter('top100Counter', 'trackPageview', []);
        getCounter('top100CounterDemo', 'trackPageview', []);
    },
    sendCVAdblock: hasAdblock => getCounter('top100Counter', 'sendCustomVars',
        [{ adblock: hasAdblock ? 'yes' : 'no' }]),

    // top100CounterDemo
    setPageViewMedia: (
        title = '',
        description = '',
        url = null,
    ) => (
        getCounter('top100CounterDemo', 'trackPageViewMedia',
            [{
                title,
                description,
                url,
                itemId: url,
                type: 'WebPage',
                datePublished: '2022-01-01T14:00:06+03:00',
                author: {
                    name: 'Статистика SberAds',
                },
            }])
    ),
    sendCVDemoAdblock: hasAdblock => getCounter('top100CounterDemo', 'sendCustomVars',
        [{ 'catalogueTop100::adblock': hasAdblock ? 'yes' : 'no' }]),
    sendCVShowTabPopular: () => (
        getCounter('top100CounterDemo', 'sendCustomVars',
            [{ 'catalogueTop100::home::tabs': 'show_block_popular' }])
    ),
    sendCVShowTabNew: () => (
        getCounter('top100CounterDemo', 'sendCustomVars',
            [{ 'catalogueTop100::home::tabs': 'show_block_new' }])
    ),
    sendCVTabLinkStatistic: () => (
        getCounter('top100CounterDemo', 'sendCustomVars',
            [{ 'catalogueTop100::home::tabs::project': 'go_to_site_stat' }])
    ),
    sendCVRatingLinkStatistic: () => (
        getCounter('top100CounterDemo', 'sendCustomVars',
            [{ 'catalogueTop100::rating::project': 'go_to_site_stat' }])
    ),
    sendCVOpenRatingCategory: category => (
        getCounter('top100CounterDemo', 'sendCustomVars',
            [{ 'catalogueTop100::home::largeCategories': category }])
    ),
    siteCatalogueClickDemo: () => (
        getCounter('top100CounterDemo', 'sendCustomVars', [{ catalogueTop100: 'site_catalogue_click' }])
    ),
    analyticsClickDemo: () => (
        getCounter('top100CounterDemo', 'sendCustomVars', [{ catalogueTop100: 'analytics_click' }])
    ),
    ratingClickDemo: () => (
        getCounter('top100CounterDemo', 'sendCustomVars', [{ catalogueTop100: 'rating_click' }])
    ),
    demoClickDemo: () => (
        getCounter('top100CounterDemo', 'sendCustomVars', [{ catalogueTop100: 'demo_click' }])
    ),
    addSiteToRatingClick: () => (
        getCounter('top100CounterDemo', 'sendCustomVars', [{ catalogueTop100: 'add_site_to_rating' }])
    ),
    mySitesClick: () => (
        getCounter('top100CounterDemo', 'sendCustomVars', [{ catalogueTop100: 'my_sites' }])
    ),
};

export const eventsYm = {
    setPageView: (url) => {
        getCounter('yaCounter', 'hit', [url]);
    },
    clickSearchButton: () => (
        getCounter('yaCounter', 'reachGoal', ['click_search_button'])),
    clickBlockPopular: () => (
        getCounter('yaCounter', 'reachGoal', [
            'click_block_popular-new_switcher',
        ])),
    showBlockPopular: () => (
        getCounter('yaCounter', 'reachGoal', ['show_block_popular'])),
    showBlockNew: () => (
        getCounter('yaCounter', 'reachGoal', ['show_block_new'])),
    clickBlockPopularLink: () => (
        getCounter('yaCounter', 'reachGoal', ['click_block_popular_link'])),
    clickBlockNewLink: () => (
        getCounter('yaCounter', 'reachGoal', ['click_block_new_link'])),
    visitLikes: () => getCounter('yaCounter', 'reachGoal', ['Visit_likes']),
    clickPromoLandingBtn: () => (
        getCounter('yaCounter', 'reachGoal', ['sites_catalogue_banner_885x180'])
    ),
    siteCatalogueClick: () => (
        getCounter('yaCounter', 'reachGoal', ['site_catalogue_click'])
    ),
    analyticsClick: () => (
        getCounter('yaCounter', 'reachGoal', ['analytics_click'])
    ),
    ratingClick: () => (
        getCounter('yaCounter', 'reachGoal', ['rating_click'])
    ),
    demoClick: () => (
        getCounter('yaCounter', 'reachGoal', ['demo_click'])
    ),
};

export const eventsMyTracker = {
    setPageView: () => getCounter('_tmr', 'push', [{ id: '3262275', type: 'pageView', start: (new Date()).getTime() }]),
};
