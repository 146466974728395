import { Store } from 'redux';
import { IAction } from 'typings/redux';
import {
    createConstants,
    createReducer,
    generateActions,
    reduxFetch,
} from '@redux/helpers';
import api from '@constants/api';

interface IState {
    isRequest: boolean;
    apiAuth: boolean|null;
    isAuth: boolean; // TODO - убить это apiAuth и сделать нормальною проверку авторизации
}

export const initialState: IState = {
    isRequest: false,
    apiAuth: null,
    isAuth: false,
};

export const UPDATE_AUTH = 'UPDATE_AUTH';
export const CHECK_AUTH = createConstants('CHECK_AUTH');


export const reducer = {
    [CHECK_AUTH.REQUEST]: {
        reducer: (state: IState) => ({
            ...state,
            request: true,
        }),
    },
    [CHECK_AUTH.RECEIVE]: {
        reducer: (state: IState, action: IAction) => ({
            ...state,
            request: false,
            apiAuth: !!action.payload.id,
        }),
    },
    [CHECK_AUTH.FAILURE]: {
        reducer: (state: IState) => ({
            ...state,
            request: false,
            apiAuth: false,
        }),
    },
    [UPDATE_AUTH]: {
        action: (authState: 'login'|'logout') => ({
            type: UPDATE_AUTH,
            payload: { authState },
        }),
        reducer: (state: IState, action: IAction) => {
            const { authState } = action.payload;

            return {
                isAuth: authState === 'login',
            };
        },
    },
};

export const checkAuth = (): any => (dispatch: Store['dispatch']) => reduxFetch({
    dispatch,
    constant: CHECK_AUTH,
    url: `${api.reportsV1}/user/current`,
    getData: data => ({ ...data.data }),
});

export const actions = generateActions(reducer);
export default createReducer(reducer, initialState);
