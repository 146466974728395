import { actions as actionsFilters } from '@redux/modules/filters';
import { fetchCategories, actions as actionsCategories } from '@redux/modules/configs/category';
import { fetchRegion } from '@redux/modules/configs/region';
import { fetchTypes } from '@redux/modules/configs/types';
import { fetchPosition } from '@redux/modules/position';
import { fetchTotals } from '@redux/modules/totals';

export const fetchData = async ({ params }) => {
    const { query, query: { resourceId } } = params;
    const { dispatch, getState } = params.store;
    const promises = [];

    let newQuery = { ...query };

    if (resourceId) {
        promises.push(await dispatch(fetchPosition(Number(resourceId))));

        const { position: { body } } = getState();

        newQuery = { ...body };
    }

    const {
        category,
        region,
        types,
        totals,
    } = getState();

    if (!category.loaded) promises.push(await dispatch(fetchCategories()));
    if (!region.loaded) promises.push(await dispatch(fetchRegion()));
    if (!types.loaded) promises.push(await dispatch(fetchTypes()));
    if (!totals.loaded) promises.push(await dispatch(fetchTotals()));

    const { region: updatedRegion, category: updatedCategory } = getState();
    let { categoryId, regionId } = newQuery;

    categoryId = Number(categoryId);
    regionId = Number(regionId);

    if (categoryId) {
        dispatch(
            actionsCategories.SET_SUBCATEGORY_DATA(
                updatedCategory.data,
                categoryId,
            ),
        );
    }

    dispatch(
        actionsCategories.SET_CATEGORY_DATA(
            updatedCategory.allTopicIds,
            updatedCategory.data,
        ),
    );

    if (regionId) {
        dispatch(
            actionsFilters.SET_REGION_IDS(regionId, updatedRegion.data),
        );
    }

    dispatch(
        actionsFilters.SET_INITIAL_FILTERS(newQuery),
    );

    return Promise.all(promises);
};
