import loadable from '@loadable/component';
import { AppRoute } from 'typings/AppRoute';
import { fetchData } from '@containers/Sites/fetchData';
import { openGraph } from '@configs/openGraph';
import { baseIcons } from '@configs/favicons';

const Search = loadable(() => import('./Search'));

const route: AppRoute = {
    exact: true,
    path: '/search',
    id: 3,
    component: Search,
    fetchData,
    openGraph,
    favicons: baseIcons,
};

export default route;
