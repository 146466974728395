import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import { setRuntimeVariable } from '@redux/modules/runtime';
import { MOBILE_WIDTH } from '@constants/global';

/**
 * Проверяем ширину и устанавливаем isMobile
 */
export const useMobileCheck = (isMobile: boolean): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        const checkWidth = (): void => {
            const activeMobileState: boolean = window.innerWidth < MOBILE_WIDTH;

            if (isMobile !== activeMobileState) {
                dispatch(setRuntimeVariable({ name: 'isMobile', value: activeMobileState }));
            }
        };

        if (__BROWSER__) checkWidth();

        const onResize = debounce(checkWidth, 200);
        window.addEventListener('resize', onResize);

        // cleaner
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isMobile, dispatch]);
};
