export const openGraphDefault = {
    title: 'Рейтинг сайтов - Рамблер Топ-100',
    image: '/top100Preview.jpg',
    description: 'Рейтинг сайтов категории. Бесплатная аналитика для вашего сайта: сплиты, отчеты в реальном времени, карта кликов и другие инструменты веб-аналитики.',
    url: '/',
};

export const openGraphReplacement = '{seoTitle}';

export const openGraph = {
    title: `Рейтинг сайтов «${openGraphReplacement}» - Рамблер Топ-100`,
    image: '/top100Preview.jpg',
    description: `Рейтинг сайтов категории «${openGraphReplacement}». Бесплатная аналитика для вашего сайта: сплиты, отчеты в реальном времени, карта кликов и другие инструменты веб-аналитики.`,
    url: '/',
};
