import { Store } from 'redux';
import api from '@constants/api';

import { createConstants, reduxFetch } from '@redux/helpers';

import { normalizeTypes } from '@tools/normalizeData';

const typesConstants = createConstants('GET_TYPES');

type PayloadData = ITypes[];

interface IState {
    loaded: boolean;
    request: boolean;
    error: boolean;
    data: PayloadData;
    errorMessage: {};
}

interface IAction {
    type: string;
    payload: {
        data: PayloadData;
        error: boolean;
    };
}

type Reducer = (
    state: IState,
    action: IAction
) => IState;

export const initialState: IState = {
    loaded: false,
    request: false,
    error: false,
    data: [],
    errorMessage: {},
};

export const setRequest = (state: IState) => ({
    ...state,
    request: true,
    loaded: false,
});

export const setData = (state: IState, action: IAction) => ({
    ...state,
    request: false,
    error: false,
    loaded: true,
    data: normalizeTypes(action.payload.data),
});

export const setError = (state: IState, action: IAction) => ({
    ...state,
    request: false,
    error: true,
    loaded: false,
    ...action.payload,
});

const reducer: Reducer = (state = initialState, action) => {
    switch (action.type) {
        case typesConstants.REQUEST: return setRequest(state);
        case typesConstants.RECEIVE: return setData(state, action);
        case typesConstants.FAILURE: return setError(state, action);

        default:
            return state;
    }
};

/**
 * Запрос за списком типов
 */
export const fetchTypes = (): any => (
    dispatch: Store['dispatch'],
) => reduxFetch({
    dispatch,
    constant: typesConstants,
    url: `${api.reportsV1}/type`,
    body: {},
});

export default reducer;
