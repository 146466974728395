import { Store } from 'redux';
import api from '@constants/api';
import { customSort } from '@tools/sort';

import { normalizeSubcategories, normalizeData } from '@tools/normalizeData';

import {
    createConstants,
    createReducer,
    generateActions,
    reduxFetch,
} from '@redux/helpers';

type PayloadData = ICategoryType[];

const categoryConstants = createConstants('GET_CATEGORIES');

export interface IState {
    loaded: boolean;
    request: boolean;
    error: boolean;
    errorMessage: {};
    data: { [id: number]: ICategoryType };
    allTopicIds: number[];
    topicIds: number[];
    topLevelTopicIds: number[];
    categoryData: ICategoryData[];
    subcategoryData: ICategoryData[];
}

interface IAction {
    type: string;
    payload: {
        data: PayloadData;
        id?: number;
        categories?: ICategoryType;
        error: boolean;
        allTopicIds?: number[];
        errorMessage: {};
    };
}

type Reducer = (
    state: IState,
    action: IAction
) => IState;

export const initialState: IState = {
    loaded: false,
    request: false,
    error: false,
    errorMessage: {},
    topicIds: [],
    topLevelTopicIds: [],
    allTopicIds: [],
    data: {},
    subcategoryData: [],
    categoryData: [],
};

export const reducer = {
    [categoryConstants.REQUEST]: {
        reducer: (state: IState) => ({
            ...state,
            request: true,
        }),
    },
    [categoryConstants.FAILURE]: {
        reducer: (state: IState, action: IAction) => ({
            ...state,
            request: false,
            error: true,
            loaded: false,
            ...action.payload,
        }),
    },
    [categoryConstants.RECEIVE]: {
        reducer: (state: IState, action: IAction) => {
            const { data } = action.payload;

            const mappedData = data.reduce(
                (acc, current) => ({ ...acc, [current.id]: current }),
                {},
            );

            const topLevelTopicIds = [];
            const topicIds = [];

            data.forEach((category) => {
                if (category.weight > 0) {
                    topLevelTopicIds.push(category.id);
                } else {
                    topicIds.push(category.id);
                }
            });

            // сортировка по весу
            topLevelTopicIds.sort((a, b) => (
                customSort(mappedData[b].weight, mappedData[a].weight)
            ));
            // сортировка в алфавитном порядке
            topicIds.sort((a, b) => (
                customSort(mappedData[a].title.toLowerCase(), mappedData[b].title.toLowerCase())
            ));
            // получаем список всех элементов, и тоже сортируем в алфавитном порядке
            const allTopicIds = [...topLevelTopicIds].sort((a, b) => (
                customSort(mappedData[a].title.toLowerCase(), mappedData[b].title.toLowerCase())
            )).concat(topicIds);

            return {
                ...state,
                request: false,
                error: false,
                loaded: true,
                allTopicIds,
                topicIds,
                data: mappedData,
                topLevelTopicIds,
            };
        },
    },
    SET_CATEGORY_DATA: {
        action: (allTopicIds, data) => ({
            type: 'SET_CATEGORY_DATA',
            payload: { allTopicIds, data },
        }),
        reducer: (state: IState, action: IAction) => {
            const { payload } = action;

            return {
                ...state,
                categoryData: normalizeData(payload.allTopicIds, payload.data),
            };
        },
    },
    SET_SUBCATEGORY_DATA: {
        action: (data, id) => ({
            type: 'SET_SUBCATEGORY_DATA',
            payload: { data, id },
        }),
        reducer: (state: IState, action: IAction) => {
            const { payload } = action;

            return {
                ...state,
                subcategoryData: normalizeSubcategories(payload.data, payload.id),
            };
        },
    },
};

/**
 * Запрос за списком категорий
 */
export const fetchCategories = (): any => (
    dispatch: Store['dispatch'],
) => reduxFetch({
    dispatch,
    constant: categoryConstants,
    url: `${api.reportsV1}/category`,
    body: {
        mirtesen: 1,
    },
});

export const actions: Record<string, any> = generateActions(reducer);
export default createReducer(reducer, initialState);
