import { IAction } from 'typings/redux';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import { IMeta } from 'typings/Form';

import { createReducer, generateActions } from '@redux/helpers';

interface IState {
    forms: Record<string, any>;
}

interface IEmptyForm {
    fields: Record<string, any>;
    initialValues: Record<string, any>;
    serverError: string;
}

interface IFieldDataItem {
    mod: string;
    type: string;
    value: string;
    errorText: string;
    errorType: string;
    touched: boolean;
    focused: boolean;
    invalid: boolean;
    valid: boolean;
    hidden: boolean;
    asyncValidating: boolean;
}

interface IFieldDataParams {
    value?: any;
    hidden?: boolean;
    type?: string;
    meta?: IMeta;
}

interface IDataInFieldsParams {
    state: IState;
    formName: string;
    parentFieldName: string[]|number[];
    fieldName: string;
    fieldData?: Record<string, any>;
    index?: number;
    mod: string;
    isPushValue?: boolean;
    isRemoveValue?: boolean;
}

const emptyForm: IEmptyForm = {
    fields: {},
    initialValues: {},
    serverError: '',
};

const emptyField: IFieldDataItem = {
    mod: 'default',
    type: 'string',
    value: '',
    errorText: '',
    errorType: '',
    touched: false,
    focused: false,
    invalid: false,
    valid: false,
    hidden: false,
    asyncValidating: false,
};

/**
 * Initial state
 */
export const initialState: IState = {
    forms: {},
};

/**
 * Получаем дефолтное значение по типу
 */
export const getEmptyType = (type: string) => {
    let value: any = '';

    switch (type) {
        case 'string':
            value = '';
            break;
        case 'boolean':
            value = false;
            break;
        case 'array':
            value = [];
            break;
        case 'object':
            value = {};
            break;
        default:
            value = '';
    }

    return value;
};

/**
 * Формируем информацию о поле
 */
const getFieldData = (params: IFieldDataParams = {}, mod: string): IFieldDataItem => {
    const resultEmptyField = { ...emptyField };

    resultEmptyField.mod = mod;
    resultEmptyField.value = params.value;
    if (params.hidden) {
        resultEmptyField.hidden = params.hidden;
    }

    if (params.type) {
        if (mod === 'array' && params.value && params.value.length > 0) {
            resultEmptyField.value = params.value.map((item) => {
                const copyItem = { ...item };
                copyItem.type = params.type;

                if (params.type && !resultEmptyField.value) {
                    copyItem.value = getEmptyType(params.type);
                }

                return copyItem;
            });

            return { ...resultEmptyField, ...params.meta };
        } if (!resultEmptyField.value) {
            resultEmptyField.type = params.type;
            resultEmptyField.value = getEmptyType(params.type);
        }
    }

    return { ...resultEmptyField, ...params.meta };
};


/**
 * Получаем измененный кусок fields
 */

export const getDataInFields = ({
    state,
    formName,
    parentFieldName,
    fieldName,
    fieldData,
    index,
    isPushValue = false,
    isRemoveValue = false,
}: IDataInFieldsParams) => {
    const currentForm = state.forms[formName];

    if (!currentForm) {
        return {};
    }

    const currentFields = { ...currentForm.fields };

    interface IValidItem {
        valid: boolean;
        asyncValidating: boolean;
    }

    /**
     * рекурсия проверки valid и asyncValidating
     */
    const recursion = (item: IFieldDataItem): IValidItem => {
        let valid = false;
        let asyncValidating = false;

        if (isObject(item.value) && !isArray(item.value)) {
            const keys = Object.keys(item.value);

            const result = keys.map((subKey) => {
                const data = item.value[subKey];
                if (!isString(data.value)) {
                    return recursion(data.value);
                }
                return { valid: data.valid, asyncValidating: data.asyncValidating };
            });

            valid = result.filter(i => !i.valid).length === 0;
            asyncValidating = result.filter(i => i.asyncValidating).length > 0;
        } else if (isArray(item.value)) {
            const result = item
                .value
                .map(subItem => recursion(subItem));

            valid = result.filter(i => !i.valid).length === 0;
            asyncValidating = result.filter(i => i.asyncValidating).length > 0;
        } else {
            const { valid: newValid, asyncValidating: newAsyncValidating } = item;
            valid = newValid;
            asyncValidating = newAsyncValidating;
        }

        return { valid, asyncValidating };
    };

    // сначала спускаемся вниз по дереву и применяем данные
    if (parentFieldName.length) {
        let currentField = currentFields;
        const structure = {};

        parentFieldName.forEach((key, i) => {
            if (typeof key === 'string') {
                currentField = !currentField[key] && currentField.value
                    ? currentField.value[key]
                    : currentField[key];
            } else if (typeof key === 'number') {
                currentField = currentField.value[key];
            }

            structure[i] = currentField;

            if (parentFieldName.length === (i + 1)) {
                if (isRemoveValue) {
                    const value = [
                        ...currentField.value[fieldName].value,
                    ].filter((item, curIndex) => curIndex !== index);

                    currentField.value = {
                        ...currentField.value,
                        [fieldName]: {
                            ...currentField.value[fieldName],
                            value,
                        },
                    };
                }

                if (!isPushValue) {
                    currentField.value = {
                        ...currentField.value,
                        [fieldName]: {
                            ...currentField.value[fieldName],
                            ...fieldData,
                        },
                    };
                } else {
                    currentField.value = {
                        ...currentField.value,
                        [fieldName]: {
                            ...currentField.value[fieldName],
                            value: [
                                ...currentField.value[fieldName].value,
                                fieldData,
                            ],
                        },
                    };
                }
            }
        });

        // затем идем вверх по дереву и меняем у всех родителей мета данные
        for (let i = parentFieldName.length - 1; i >= 0; i -= 1) {
            const res = recursion(structure[i]);

            structure[i].valid = res.valid;
            structure[i].invalid = !res.valid;
            structure[i].asyncValidating = res.asyncValidating;
        }

        return currentFields;
    }

    if (isRemoveValue) {
        const value = [
            ...currentFields[fieldName].value,
        ].filter((item, curIndex) => curIndex !== index);

        return {
            ...currentFields,
            [fieldName]: {
                ...currentFields[fieldName],
                value,
            },
        };
    }

    if (isPushValue) {
        return {
            ...currentFields,
            [fieldName]: {
                ...currentFields[fieldName],
                value: [
                    ...currentFields[fieldName].value,
                    fieldData,
                ],
            },
        };
    }

    return {
        ...currentFields,
        [fieldName]: {
            ...currentFields[fieldName],
            ...fieldData,
        },
    };
};

export const groupReducerCreate = (constant: string) => ({
    [constant]: {
        action: (
            formName: string,
            parentFieldName: string,
            fieldName: string,
            mod: string,
            params: Record<string, any>,
        ) => ({
            type: constant,
            payload: {
                formName, parentFieldName, fieldName, mod, params,
            },
        }),
        reducer: (state: IState, action: IAction) => {
            const {
                formName, fieldName, parentFieldName, mod, params,
            } = action.payload;

            if (!state.forms[formName]) {
                return state;
            }

            const fieldData = getFieldData(params, mod);

            const fields = getDataInFields({
                state,
                formName,
                fieldName,
                parentFieldName,
                fieldData,
                mod,
            });

            return {
                ...state,
                forms: {
                    ...state.forms,
                    [formName]: {
                        ...state.forms[formName],
                        fields,
                    },
                },
            };
        },
    },
});


export const groupReducerChange = (constant: string) => ({
    [constant]: {
        action: (
            formName,
            parentFieldName,
            fieldName,
            mod,
            fieldData,
            isPushValue = false,
        ) => ({
            type: constant,
            payload: {
                formName, parentFieldName, fieldName, mod, fieldData,
            },
            meta: { isPushValue },
        }),
        reducer: (state: IState, action: IAction) => {
            const {
                formName, fieldName, fieldData, parentFieldName, mod,
            } = action.payload;
            const { isPushValue } = action.meta;

            const fields = getDataInFields({
                state,
                formName,
                fieldName,
                parentFieldName,
                fieldData,
                mod,
                isPushValue,
            });

            return {
                ...state,
                forms: {
                    ...state.forms,
                    [formName]: {
                        ...state.forms[formName],
                        fields,
                        serverError: '',
                    },
                },
            };
        },
    },
});

export const reducer = {
    CREATE_FORM: {
        action: (formName: string, initialValues: Record<string, any> = {}) => ({
            type: 'CREATE_FORM',
            payload: { formName, initialValues },
        }),
        reducer: (state: IState, action: IAction) => {
            const { formName, initialValues } = action.payload;
            if (state.forms[formName]) {
                return state;
            }

            const newEmptyForm = {
                ...emptyForm,
                initialValues: initialValues || {},
            };

            return {
                ...state,
                forms: {
                    ...state.forms,
                    [formName]: newEmptyForm,
                },
            };
        },
    },
    REMOVE_FORM: {
        action: (payload: Record<string, any>) => ({
            type: 'REMOVE_FORM',
            payload,
        }),
        reducer: (state: IState, action: IAction) => {
            const newForms = { ...state.forms };

            // если массив, пробегаемся по нему и удаляем из объекта ненужные формы
            if (isArray(action.payload)) {
                const forms = action.payload;
                forms.forEach((formName) => {
                    if (state.forms[formName]) {
                        delete newForms[formName];
                    }
                });
                // если строка, удаляем из объекта форму
            } else {
                const formName = action.payload;
                if (state.forms[formName]) {
                    delete newForms[formName];
                }
            }

            return {
                ...state,
                forms: { ...newForms },
            };
        },
    },
    RESET_FORM: {
        action: (payload: Record<string, any>) => ({
            type: 'RESET_FORM',
            payload,
        }),
        reducer: (state: IState, action: IAction) => {
            const { formName } = action.payload;

            if (!state.forms[formName]) {
                return state;
            }

            const resetValuesThree = (recFields: Record<string, any>) => {
                const linkFields = { ...recFields };

                Object.keys(recFields).forEach((key) => {
                    const field = recFields[key];
                    const emptyValue = getEmptyType(field.type);
                    if (field.mod === 'default') {
                        linkFields[key].value = emptyValue;
                    } else if (field.mod === 'array') {
                        linkFields[key].value = [emptyValue];
                    } else if (field.mod === 'object') {
                        linkFields[key].value = resetValuesThree(field.value);
                    }
                });

                return linkFields;
            };

            const fields = resetValuesThree({ ...state.forms[formName].fields });

            return {
                ...state,
                forms: {
                    ...state.forms,
                    [formName]: {
                        ...state.forms[formName],
                        fields,
                    },
                },
            };
        },
    },
    INITIALIZE_VALUES: {
        action: (formName: string, values: Record<string, any>, isForceUpdate: boolean) => ({
            type: 'INITIALIZE_VALUES',
            payload: {
                formName,
                values,
                isForceUpdate,
            },
        }),
        reducer: (state: IState, action: IAction) => {
            const { formName, values, isForceUpdate } = action.payload;
            if (!state.forms[formName]) {
                return state;
            }

            return {
                ...state,
                forms: {
                    ...state.forms,
                    [formName]: {
                        ...state.forms[formName],
                        initialValues: {
                            ...(!isForceUpdate ? state.forms[formName].initialValues : {}),
                            ...values,
                        },
                    },
                },
            };
        },
    },
    REMOVE_FIELD: {
        action: (formName: string, parentFieldName: string, fieldName: string, mod: string) => ({
            type: 'REMOVE_FIELD',
            payload: {
                formName, parentFieldName, fieldName, mod,
            },
        }),
        reducer: (state: IState, action: IAction) => {
            const { formName, fieldName } = action.payload;

            if (!state.forms[formName]) {
                return state;
            }

            if (!state.forms[formName].fields[fieldName]) {
                return state;
            }

            const newFields = { ...state.forms[formName].fields };
            delete newFields[fieldName];

            return {
                ...state,
                forms: {
                    ...state.forms,
                    [formName]: {
                        ...state.forms[formName],
                        fields: { ...newFields },
                    },
                },
            };
        },
    },
    SET_FIELD_ERRORS: {
        action: (formName: string, errors: Record<string, any>) => ({
            type: 'SET_FIELD_ERRORS',
            payload: { formName, errors },
        }),
        reducer: (state: IState, action: IAction) => {
            const { formName, errors } = action.payload;

            if (!state.forms[formName]) {
                return state;
            }

            const newFields = { ...state.forms[formName].fields };

            Object.keys(errors).forEach((key) => {
                if (newFields[key]) {
                    newFields[key].invalid = true;
                    newFields[key].valid = false;
                    newFields[key].errorText = errors[key];
                    newFields[key].errorType = 'system';
                }
            });

            return {
                ...state,
                forms: {
                    ...state.forms,
                    [formName]: {
                        ...state.forms[formName],
                        fields: { ...newFields },
                    },
                },
            };
        },
    },
    SET_SERVER_ERROR: {
        action: (formName: string, serverError: string) => ({
            type: 'SET_SERVER_ERROR',
            payload: { formName, serverError },
        }),
        reducer: (state: IState, action: IAction) => {
            const { formName, serverError } = action.payload;

            if (!state.forms[formName]) {
                return state;
            }

            return {
                ...state,
                forms: {
                    ...state.forms,
                    [formName]: {
                        ...state.forms[formName],
                        serverError,
                    },
                },
            };
        },
    },
    REMOVE_FIELD_VALUE: {
        action: (
            formName: string,
            parentFieldName: string,
            fieldName: string,
            mod: string,
            index: number,
        ) => ({
            type: 'REMOVE_FIELD_VALUE',
            payload: {
                formName, parentFieldName, fieldName, mod, index,
            },
        }),
        reducer: (state: IState, action: IAction) => {
            const {
                formName, parentFieldName, fieldName, index, mod,
            } = action.payload;

            if (mod !== 'array') {
                return state;
            }

            const fields = getDataInFields({
                state,
                formName,
                fieldName,
                parentFieldName,
                mod,
                index,
                isRemoveValue: true,
            });

            return {
                ...state,
                forms: {
                    ...state.forms,
                    [formName]: {
                        ...state.forms[formName],
                        fields,
                    },
                },
            };
        },
    },

    // разделение одного редьюсера на несколько неймингов (для читабельности)
    ...groupReducerCreate('CREATE_FIELD'),
    ...groupReducerCreate('RESET_FIELD'),

    // разделение одного редьюсера на несколько неймингов (для читабельности)
    ...groupReducerChange('CHANGE_FIELD'),
    ...groupReducerChange('HIDE_FIELD'),
    ...groupReducerChange('SHOW_FIELD'),
    ...groupReducerChange('ASYNC_VALIDATING_FIELD'),
    ...groupReducerChange('BLUR_FIELD'),
    ...groupReducerChange('TOUCH_FIELD'),
    ...groupReducerChange('FOCUS_FIELD'),
    ...groupReducerChange('ADD_FIELD_VALUE_FIELD'),
};


export const actions: Record<string, Function> = generateActions(reducer);
export default createReducer(reducer, initialState);
