import { matchPath } from 'react-router-dom';
import { Store } from 'redux';
import { IRouteContext } from 'typings/AppRoute';
import { getCookieObject } from '@tools/getCookie';

import routes from '../client/routes';

interface IRoutesPrefetch {
    (params: {
        pathname: string;
        store: Store;
        query: Record<any, string>;
        cookie?: string;
    }): Promise<any>;
}

export const routesPrefetch: IRoutesPrefetch = ({
    pathname,
    query,
    store,
    cookie = '',
}) => new Promise((resolve) => {
    const promises = [];
    const { reg_id: regionIdOfCookie } = getCookieObject(cookie);

    routes.some((route) => {
        const routeContext: IRouteContext = matchPath(pathname, route);
        const match = routeContext !== null;
        if (match && typeof route.fetchData === 'function') {
            routeContext.params = {
                store,
                query,
                cookies: { regionIdOfCookie },
            };

            promises.push(route.fetchData(routeContext));
        }
        return match;
    });

    return Promise.all(promises).then((results) => {
        let withErrors = false;

        results.forEach(item => item.forEach((action) => {
            if (!withErrors && action && action.type.includes('FAILURE')) {
                withErrors = true;
            }
        }));

        resolve({
            state: store.getState(),
            withErrors,
        });
    });
});
