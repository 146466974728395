import loadable from '@loadable/component';
import { fetchCategories } from '@redux/modules/configs/category';
import { fetchSideBlock } from '@redux/modules/sideBlock';
import { fetchRegion } from '@redux/modules/configs/region';
import { AppRoute } from 'typings/AppRoute';
import { fetchTotals } from '@redux/modules/totals';
import { openGraphDefault } from '@configs/openGraph';
import { baseIcons } from '@configs/favicons';

const Home = loadable(() => import('./Home'));

const route: AppRoute = {
    exact: true,
    path: '/',
    id: 1,
    component: Home,
    fetchData: ({ params }) => {
        const { dispatch, getState } = params.store;

        const {
            category,
            region,
            sideBlock,
            totals,
            runtime: { regionId },
        } = getState();

        return Promise.all([
            !category.loaded ? dispatch(fetchCategories()) : Promise.resolve(),
            !sideBlock.loaded ? dispatch(fetchSideBlock(regionId)) : Promise.resolve(),
            !region.loaded ? dispatch(fetchRegion()) : Promise.resolve(),
            !totals.loaded ? dispatch(fetchTotals()) : Promise.resolve(),
        ]);
    },
    openGraph: openGraphDefault,
    favicons: baseIcons,
};

export default route;
