export interface IIcons {
    rel: string;
    href: string;
    sizes?: string;
}

export const baseIcons = [
    {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: 'baseFavicons/apple-touch-icon.png',
    },
    {
        rel: 'icon',
        sizes: '32x32',
        href: 'baseFavicons/favicon-32x32.png',
    },
    {
        rel: 'icon',
        sizes: '16x16',
        href: 'baseFavicons/favicon-16x16.png',
    },
    {
        rel: 'mask-icon',
        href: 'baseFavicons/safari-pinned-tab.svg',
    },
];
