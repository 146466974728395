interface IData {
    [id: number]: IRegionType;
}

export const getRegionIds = (id: number, data: IData): number[] => {
    if (!data) {
        return [];
    }

    const iter = (regionData: IRegionType, acc: number[]) => {
        if (!regionData) {
            return [];
        }

        if (!regionData.parent_id) {
            return acc.reverse();
        }

        return iter(data[regionData.parent_id], [...acc, regionData.parent_id]);
    };

    return iter(data[id], [id]);
};
